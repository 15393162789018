
.myCard {
  width: 100%;
  height: calc(100vh - 88px);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  overflow: hidden;
}

.myCardsx {
  width: 100%;  
  height: calc(100vh - 88px);
  background-color: rgb(255, 255, 255);
  border-radius: 8px;
  box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.4);
  
}



