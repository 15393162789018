body {
    padding: 0;
    margin: 0;
  }
  
  .App {
    height: 100vh;
    width: 100%;
  }
  
  .circle {
    background-color: #0b612c;
    border-radius: 50%;
    color: #fff;
    height: 4.5em;
    position: relative;
    width: 4.5em;
  
    border: 1px solid transparent;
  }
  
  .circleText {
    font-size: medium;
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 35%;
    transform: translate(-50%, -50%);
    width: 50%;
  }

  .circleText2 {
    font-size: small;
    text-align: center;
    height: 50%;
    left: 50%;
    position: absolute;
    top: 100%;
    transform: translate(-50%, -50%);
    width: 100%;
  }
  

.marker  span {
  display:flex;
  justify-content:center;
  align-items:center;
  box-sizing:border-box;
  width: 40px;
  height: 40px;
  color:rgb(255, 255, 255);
  
  border:solid 2px;
  border-radius: 0 70% 70%;
  box-shadow:0 0 2px rgb(0, 0, 0);
  cursor: pointer;
  transform-origin:0 0;
  transform: rotateZ(-135deg);
}
.rot { background: rgb(111, 4, 4)}
.gruen { background: rgb(5, 107, 3)}
.blau { background: rgb(40, 5, 181)}
.gelb { background: rgb(2, 113, 133)}
.orange { background: rgb(183, 113, 0)}
.stellblue { background-color: #3b82f6; /* Beispiel für ein Blau */
  color: white;
}
.feierabend { background: rgb(11, 21, 23)}

.marker b {transform: rotateZ(135deg)}

.mapboxgl-popup {
  max-width: 200px;
}

.mapboxgl-popup-content {
  text-align: center;
  font-family: 'Open Sans', sans-serif;
}
  
  