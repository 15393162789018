.fas[data-count]{
    position:relative;
}
.fas[data-count]:after{
    position: absolute;
    right: -0.75em;
    top: -.75em;
    content: attr(data-count);
    padding: .5em;
    border-radius: 10em;
    line-height: .9em;
    color: white;
    background: rgba(165, 16, 16, 0.75);
    text-align: center;
    min-width: 2em;
    font: bold .8em sans-serif;
}

