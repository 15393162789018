.marker-container {
    position: relative;
  }
  
  .info-box {
    position: absolute;
    top: 40px;
    left: 10px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
    padding: 10px;
    width: 250px;
    z-index: 10;
    border: 1px solid #ccc;
  }
  
  .info-box h4 {
    margin: 0;
    font-size: 16px;
    color: #333;
  }
  
  .info-box p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
  }
  
  .close-btn {
    position: absolute;
    top: 5px;
    right: 8px;
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #888;
  }
  
  .close-btn:hover {
    color: #000;
  }