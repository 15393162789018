/* Allgemeine Styles für alle Switches */
/* Standardgröße für alle Switches */
.custom-switch .react-switch-bg {
  width: 80px !important;
  height: 30px !important;
  border-radius: 15px !important;
  transition: background-color 0.2s ease-in-out !important;
}

/* Slider (der runde Schaltknopf) */
.custom-switch .react-switch-handle {
  width: 26px !important;
  height: 26px !important;
  top: 2px !important;
}



/* Einheitlicher Textstil für Switch-Labels */
.switch-text {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  font-size: 14px;
  font-weight: bold;
  color: white;
}
