
.App {
  font-family: sans-serif;
  
}

.react-bootstrap-table table {
  table-layout: auto;
}

.dot1 {
  border-left: 5px solid red;
}

@keyframes bounce {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-5px); }
}

.bounce {
  animation: bounce 1s infinite;
}

.leaflet-container {
  height: 100vh !important;
  width: 100% !important;
}