/*
  These :not() selectors are for preventing
  style conflicts with a selected date. You can remove them if you wish!
*/

.purpleDay{
    border: 2px solid rgba(156, 136, 255, 0.7);
  }
  
  .orangeDay {
    border: 2px solid rgba(219, 145, 60, 0.7);
  }
  
  .yellowDay{
    border: 2px solid rgba(228, 231, 36, 0.7);
  }
  
  .navyBlueDay{
    border: 2px solid rgba(52, 73, 94, 0.7);
  }
  .redDay{
    border: 3px solid rgba(192, 2, 2, 0.7);
  }
  .greenDay {
    border: 2px solid rgba(0, 100, 0, 0.7);
  }

  .blueDay {
    border: 2px solid rgba(0, 0, 200, 0.7) ;
  }



  .responsive-calendar {
    /* by setting font-size, all the elements will correspond */
    font-size: 9px !important; /* default to 10px */
  }
  
  @media (max-width: 1500px) {
    .responsive-calendar {
      font-size: 8px !important;
    }
  }
  
  @media (max-width: 1200px) {
    .responsive-calendar {
      font-size: 7px !important;
    }
  }
  
  @media (max-width: 768px) {
    .responsive-calendar {
      font-size: 6px !important;
    }
  }
  
  /* Large screens */
  @media (min-width: 2500px) {
    .responsive-calendar {
      font-size: 12px !important;
    }
  }