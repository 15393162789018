.react-bootstrap-table table { table-layout: auto !important; }

.dot1 {
    color: red;
    font-weight: bold;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
  }
  
  th, td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: center;
  }
  
  th {
    background-color: #f4f4f4;
  }
  
  .bg-success {
    background-color: #28a745 !important;
  }
  
  .bg-danger {
    background-color: #dc3545 !important;
  }
  
  .bg-warning {
    background-color: #ffc107 !important;
  }
  
  .text-center {
    text-align: center;
  }
  