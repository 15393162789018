.custom-ui {
    text-align: center;
    width: 500px;
    padding: 40px;
    border-radius: 15px;
    background: #73860277;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: rgb(0, 0, 0);
}

/*.custom-ui > button {
    display: inline-block;
    text-decoration: none;
    font-family: 'Nunito', sans-serif;
    background: #57611e;
    color: #d3e05f;
    padding: 10px 30px;
    
    border-radius: 50px;
    font-size: 14px;
    width: 100%;
    max-width: 45%;
    margin: 10px;
}*/

